import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
import enterprise1 from "@/images/enterprise1.jpg";
import enterprise2 from "@/images/enterprise2.jpg";
const ServiceDetailsContentFour = () => {
  return (
    <div className="serviceArea">
      <img src={enterprise1} alt="" />
      <h2>Optimizing And Facilitating Enterprises So They Work Efficiently</h2>
      <p>
        Kloudxel is here to help you with every type of custom development 
        or service that your enterprise needs. We aim to provide enterprises 
        with ways that increase their productivity and achieve business goals 
        faster with Microsoft 365 and SharePoint tools, which have been 
        specially designed to do just this. This allows you to keep your 
        business processes, tasks, activities, and teams in sync and focused 
        on achieving the set goals.
      </p>
      <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={enterprise2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>Enterprise Services</h3>
          <p>
            We offer the following services:
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Microsoft 365 Services
            </li>
            <li>
              <i className="fa fa-check-square"></i>Sharepoint Services
            </li>
            <li>
              <i className="fa fa-check-square"></i>Customized Development Services For Enterprise
            </li>
          </ul>
        </div>
      </div>
      <p>
        With a range of Microsoft 365 and Sharepoint Services, including the development 
        of customized apps for your enterprise, creating plans to manage and schedule your 
        enterprise resources and assets, integrations and operations support services, 
        we are ready to cater to your enterprise needs.
      </p>
    </div>
  );
};

export default ServiceDetailsContentFour;
