import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import ServiceDetailsFour from "@/components/service-details-4";
import CallToActionOne from "@/components/call-to-action-one";
import MenuContextProvider from "@/context/menu-context";
import SearchContextProvider from "@/context/search-context";
import HeaderOne from "@/components/header-one";
import slider51 from "@/images/slider/5_1.jpg";

const ServiceDetailsPageFour = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Enterprise Collaboration Using Microsoft Tools">
          <HeaderOne />
          <PageBanner title="Enterprise Collaboration" name="Services" image={slider51}/>
          <ServiceDetailsFour />
          <CallToActionOne extraClassName="ready" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ServiceDetailsPageFour;
