import React, { Fragment } from "react";

const ServiceSidebar = () => {
  return (
    <Fragment>
      <aside className="widget categories">
        <h3 className="widget_title">Our Services</h3>
        <div className="meipaly_categorie_widget">
          <ul>
            <li>
              <a href="/service-details">Custom Business Application</a>
            </li>
            <li>
              <a href="/service-details-2">DevOps</a>
            </li>
            <li>
              <a href="/service-details-3">UI/UX Designing</a>
            </li>
            <li>
              <a href="/service-details-4">Enterprise Collaboration</a>
            </li>
            <li>
              <a href="/service-details-5">Software Consulting</a>
            </li>
          </ul>
        </div>
      </aside>
      <aside className="widget categories">
        <div className="meipaly_services_help">
          <h4>Need Our Help?</h4>
          <p>
            We’re here for you! You can reach us through our email or contact us at this number.
          </p>
          <h3>kloudxel@gmail.com</h3>
          <h3>666 888 000</h3>
        </div>
      </aside>
    </Fragment>
  );
};

export default ServiceSidebar;
